<template>
  <div class="auth">
    <AppHeader :type="1"></AppHeader>
    <div class="content">
      <Regstep
        :emialInfo="emialInfo"
        :companyInfo="companyInfo"
        :personInfo="personInfo"
      ></Regstep>
      <!-- <img class="topImg"
           src="@/assets/tiao_4.png"
           alt /> -->
      <p class="title">申请人实名信息</p>
      <el-form class="form" ref="form" :model="form" label-width="110px" :rules="rules">
        <el-form-item label="申请人姓名：" prop="applyName">
          <el-input
            maxlength="10"
            placeholder="请输入申请人姓名"
            v-model="form.applyName"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请人职务：" prop="applyPost">
          <el-input
            maxlength="10"
            placeholder="请输入申请人职务"
            v-model="form.applyPost"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号："  prop="cardId">
          <el-input
            maxlength="18"
            placeholder="请输入身份证号"
            v-model="form.cardId"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请人手机号：" prop="applyPhone">
          <el-input
            maxlength="11"
            placeholder="请输入申请人手机号"
            v-model="form.applyPhone"
          >
          </el-input>
          <i v-show="!isInter" @click="getVertify" class="suf suffix"
            >获取短信验证码</i
          >
          <i v-show="isInter" class="suf suffix2">({{ inter }}s)</i>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="vertify">
          <el-input
            maxlength="6"
            placeholder="请输入短信验证码"
            v-model="form.vertify"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传身份证个人信息面：" prop='imageUrl'>
          <i
            @click="clearFront"
            v-if="form.imageUrl"
            style="color: rgb(211, 67, 73);position: absolute;left: 110px;top: -10px;z-index: 5;"
            class="el-icon-error"
          ></i>
          <el-upload
            ref="upload"
            class="avatar-uploader"
            :show-file-list="false"
            :headers="uploadHeaders"
            action="/api/ump/ep/uploadidcardfront"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :disabled="form.imageUrl !== ''"
          >
            <img
              @click="
                viewImg(pdfUrl ? pdfUrl : form.imageUrl, pdfUrl ? 'pdf' : 'image')
              "
              v-if="form.imageUrl"
              :src="form.imageUrl"
              class="avatar"
              id="embed"
            />
            <div v-loading="frontLoading" v-else>
              <img src="@/assets/upload.png" />
              <img class="example" src="@/assets/idCardFront.png" />
            </div>
          </el-upload>
          <span v-if="form.imageUrl" class="uploaded">(已上传)</span>
          <p class="upload-tip">
            格式要求PNG、JPG、JPGE、PDF格式，上传大小不超过5M
          </p>
        </el-form-item>
        <el-form-item label="上传身份证国徽面：" prop='imageUrl1'>
          <i
            @click="clearBack"
            v-if="form.imageUrl1"
            style="color: rgb(211, 67, 73);position: absolute;left: 110px;top: -10px;z-index: 5;"
            class="el-icon-error"
          ></i>
          <el-upload
            ref="upload1"
            class="avatar-uploader"
            :show-file-list="false"
            :headers="uploadHeaders"
            action="/api/ump/ep/uploadidcardback"
            :on-success="handleAvatarSuccess1"
            :before-upload="beforeAvatarUpload1"
            :disabled="form.imageUrl1 !== ''"
          >
            <img
              @click="
                viewImg(
                  pdfUrl1 ? pdfUrl1 : form.imageUrl1,
                  pdfUrl1 ? 'pdf' : 'image'
                )
              "
              v-if="form.imageUrl1"
              :src="form.imageUrl1"
              class="avatar"
              id="embed1"
            />
            <div v-loading="backLoading" v-else>
              <img src="@/assets/upload.png" />
              <img class="example" src="@/assets/idCardBack.png" />
            </div>
          </el-upload>
          <span v-if="form.imageUrl1" class="uploaded">(已上传)</span>
          <p class="upload-tip">
            格式要求PNG、JPG、JPGE、PDF格式，上传大小不超过5M
          </p>
        </el-form-item>
      </el-form>
      <!-- <div class="agree">
        <el-checkbox v-model="checked">
          同意
        </el-checkbox>
        <span @click="centerDialogVisible=true"
              style="font-size:14px;color:#2B4FA5;cursor:pointer">《至信链开放平台服务协议》</span>
      </div> -->
      <div class="footBtn2">
        <el-button class="submit" type="primary" @click="onSubmit('form')"
          >提交</el-button
        >
        <el-button style="margin-left:35px" class="save" @click="prestep"
          >上一步</el-button
        >
        <el-button style="margin-left:35px" class="save" @click="onSave"
          >保存草稿</el-button
        >
      </div>
    </div>
    <AppFooter></AppFooter>
    <el-dialog width="600px" class="img-dialog" :visible.sync="imgDialog">
      <iframe
        height="500px"
        v-if="viewType === 'pdf'"
        frameborder="0"
        width="100%"
        :src="previewImg"
        alt=""
      ></iframe>
      <img v-else :src="previewImg" style="width: 100%" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible" width="50%" center>
      <img width="100%" src="@/assets/xieyi.png" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button
          class="submit"
          type="primary"
          @click="centerDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 提示框组件 -->
    <Dialog
      :isvisible.sync="isvisible"
      :dailogData="dailogData"
      @colseDialog="colseDialog"
      @operateButton="operateButton"
    >
    </Dialog>
  </div>
</template>
<script>
import AppHeader from "@/components/layer/AppHeader";
import AppFooter from "@/components/layer/AppFooter";
import axios from "axios";
import { enterRelInfo } from "./dialog";
import {
  uploadDocument,
  sendApply,
  getTradeParent,
  getTradeChild,
  getApply,
  getUserInfo,
  downloadCardFront,
  downloadCardBack,
  sendPhoneCode,
  updateApply
} from "@/api/user";
import { exportExcel } from "@/utils/index";
import { statusCode } from "@/config";
import { setCookie, getCookie, delCookie, getUuid } from "@/utils";
import Regstep from "@/components/common/Regstep";
import Dialog from "@/components/common/Dialog";

const defaultImg = require("@/assets/pdf.png");

export default {
  components: {
    AppHeader,
    AppFooter,
    Regstep,
    Dialog
  },
  data() {
      var validateChinglish = (rule, value, callback)=> {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
      const reg = /^[a-zA-Z\u4e00-\u9fa5()（）]+$/;
      if (!value && value !== "0") {
        callback(new Error('申请人姓名不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('申请人姓名格式不正确'))
      } else {
        callback()
      }
      // return false;
    }
    var validateChinglish1 = (rule, value, callback)=> {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
      const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
      if (!value && value !== "0") {
        callback(new Error('申请人职务不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('申请人职务格式不正确'))
      } else {
        callback()
      }
      // return false;
    }
    var validateCardId =(rule, value, callback)=>{
      const cardIdReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if(!value.trim()){
        callback(new Error('身份证号不能为空'))
      } else if(!cardIdReg.test(value)){
        callback(new Error('身份证号格式不正确'))
      } else {
        callback()
      }
    }
      var validatePhone= (rule, value, callback)=> {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
       const reg = /^1[3456789]\d{9}$/;
       if (!value && value !== "0") {
        callback(new Error('申请人手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    return {
      isvisible: false, // 提示框显示
      dailogData: {}, // 提示框数据对象
      emialInfo: {
        type: 3,
        name: "已完成"
      },
      companyInfo: {
        type: 3,
        name: "已完成"
      },
      personInfo: {
        type: 2,
        name: "进行中"
      },
      uploadHeaders: {},
      centerDialogVisible: false,
      imgDialog: false,
      frontLoading: false,
      backLoading: false,
      value: [],
      options: [],
      form: {
        applyName: "",
        applyPost: "",
        applyPhone: "",
        vertify: "",
        cardId: "",
        imageUrl: "",
        imageUrl1: "",
      },
      cardFrontId: "",
      cardBackId: "",
      inter: 60,
      isInter: false,
      imageUrl: "",
      pdfUrl: "",
      pdfUrl1: "",
      // checked: false,
      imageUrl1: "",
      busiLicenseId: "",
      letterId: "",
      categoryPId: "",
      categoryCId: "",
      state: "",
      previewImg: "",
      viewType: "",
      loading: "",
      rules:{
         applyName: [
          { required: true, trigger: 'blur', validator:validateChinglish }
        ],
        applyPost:[
          { required: true, trigger: 'blur', validator:validateChinglish1 }
        ],
        cardId:[
          { required: true, trigger: 'blur', validator:validateCardId }
        ],
        applyPhone:[
          { required: true, trigger: 'blur', validator:validatePhone }
        ],
        vertify:[
          { required: true, message: '短信验证码不能为空', trigger: 'blur' }
        ],
        imageUrl: [{ required: true, message: '请上传身份证正面', trigger: 'change' }],
        imageUrl1: [{ required: true, message: '请上传身份证反面', trigger: 'change' }],
      }
    };
  },
  mounted() {
    this.uploadHeaders["X-XSRF-TOKEN"] = getCookie("zxtoken")
      ? decodeURIComponent(getCookie("zxtoken"))
      : "";
    this.uploadHeaders["Cloud-Trace-Id"] = getUuid();

    const userData = JSON.parse(localStorage.getItem("userData"));
    const that = this;
    if (userData.epId === 0) {
      if (localStorage.getItem(`applyDataSave${userData.uid}`) != null) {
        const data = JSON.parse(
          localStorage.getItem(`applyDataSave${userData.uid}`)
        );
        that.form.applyName = data.contact;
        that.form.applyPost = data.title;
        that.form.applyPhone = data.mobile;
        that.form.cardId = data.cardId;
        that.form.vertify = data.verifyCode;
        that.cardFrontId = data.cardFrontId;
        that.cardBackId = data.cardBackId;
        that.downloadCardFront(that.cardFrontId);
        that.downloadCardBack(that.cardBackId);
        // that.getTradeParent()
        // if (data.categoryPId !== '') {
        //   setTimeout(() => {
        //     that.getTradeChilds(data.categoryPId)
        //   }, 1000)
        // }
      } else {
        // that.getTradeParent()
      }
    } else {
      if (localStorage.getItem(`applyDataSave${userData.uid}`) != null) {
        const data = JSON.parse(
          localStorage.getItem(`applyDataSave${userData.uid}`)
        );
        that.form.applyName = data.contact;
        that.form.applyPost = data.title;
        that.form.applyPhone = data.mobile;
        that.form.cardId = data.cardId;
        that.form.vertify = data.verifyCode;
        that.cardFrontId = data.cardFrontId;
        that.cardBackId = data.cardBackId;
        that.state = 1;
        that.cardFrontId && that.downloadCardFront(that.cardFrontId);
        that.cardBackId && that.downloadCardBack(that.cardBackId);
        // that.getTradeParent()
        // if (data.categoryPId !== '') {
        //   setTimeout(() => {
        //     that.getTradeChilds(data.categoryPId)
        //   }, 1000)
        // }
      } else {
        getApply().then(res => {
          this.$message.closeAll();
          if (res.detail) {
            that.state = res.detail.state;
            that.form.applyName = res.detail.contact;
            that.form.applyPost = res.detail.title;
            that.form.applyPhone = res.detail.mobile;
            that.form.vertify = "";
            this.form.cardId = res.detail.idcard;
            that.cardFrontId = res.detail.idcardfrontid;
            that.cardBackId = res.detail.idcardbackid;
            that.downloadCardFront(res.detail.idcardfrontid);
            that.downloadCardBack(res.detail.idcardbackid);
            // that.getTradeParent()
            // setTimeout(() => {
            //   that.getTradeChilds(res.detail.categoryPid)
            // }, 1000)
          } else {
            this.$message.error(res.retMsg);
          }
        });
      }
    }
  },
  methods: {
    clearFront() {
      // this.imageUrl = "";
      this.form.imageUrl ="";
      this.pdfUrl = "";
      this.letterId = "";
      this.cardFrontId = "";
    },
    clearBack() {
      // this.imageUrl1 = "";
      this.form.imageUrl1 ="";
      this.pdfUrl1 = "";
      this.letterId = "";
      this.cardBackId = "";
    },
    viewImg(url, type) {
      this.previewImg = url;
      this.viewType = type;
      if (type == "pdf") {
        const isIE = window.ActiveXObject || "ActiveXObject" in window;

        if (isIE) {
          this.isvisible = true;
          this.dailogData = enterRelInfo.isIE;
        } else {
          this.imgDialog = true;
        }
      } else {
        this.imgDialog = true;
      }
    },
    prestep() {
      this.saveFormData();
      this.$router.push({
        name: "enterpriseInfo"
      });
    },
    validatePhone(value) {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
      if (!value && value !== "0") {
        return false;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        return true;
      }
      return false;
    },
    validateChinglish(value) {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
      if (!value && value !== "0") {
        return false;
      }
      const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
      if (reg.test(value)) {
        return true;
      }
      return false;
    },
    validateNumandEng(value) {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "");
      value = value.replace(/\s+/g, "");
      if (!value && value !== "0") {
        return false;
      }
      const reg = /^([0-9A-Z]{15}|[0-9A-Z]{18})$/;
      if (reg.test(value)) {
        return true;
      }
      return false;
    },
    downloadCardFront(cardFrontId) {
      const that = this;
      downloadCardFront(cardFrontId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll();
          if (res.indexOf("application/pdf") < 0) {
            that.frontLoading = false;
            // that.imageUrl = res;
            that.form.imageUrl =res;
          } else {
            that.pdfUrl = res;
            that.form.imageUrl =defaultImg;
            // that.imageUrl = defaultImg;
          }
        });
    },
    downloadCardBack(cardBackId) {
      const that = this;
      downloadCardBack(cardBackId)
        .then(
          res =>
            `data:${res.headers["content-type"]};base64,${btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`
        )
        .then(res => {
          this.$message.closeAll();
          if (res.indexOf("application/pdf") < 0) {
            that.backLoading = false;
            // that.imageUrl1 = res;
            that.form.imageUrl1 = res;
          } else {
            that.pdfUrl1 = res;
            that.form.imageUrl1 = defaultImg;
            // that.imageUrl1 = defaultImg;
          }
        });
    },
    getTradeParent() {
      const data = {
        pid: ""
      };
      const that = this;
      getTradeParent(data).then(res => {
        this.$message.closeAll();
        if (res.retCode == 0) {
          this.options = res.detail.map((value, i) => ({
            id: value.id,
            name: value.name,
            children: []
          }));
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    onSubmit(formName) {
      this.saveFormData();
      this.$message.closeAll();
      const userData = JSON.parse(localStorage.getItem("userData"));
      const corData = JSON.parse(
        localStorage.getItem(`corDataSave${userData.uid}`)
      );
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      const data = {
        contact: this.form.applyName,
        title: this.form.applyPost,
        mobile: this.form.applyPhone,
        idcard: this.form.cardId,
        idcardFrontId: this.cardFrontId,
        idcardBackId: this.cardBackId,
        verifyCode: this.form.vertify
      };
      if (corData) {
        data.epName = corData.epName;
        data.creditCode = corData.creditCode;
        data.representative = corData.representative;
        data.busiLicenseId = corData.busiLicenseId;
        data.officialLetterId = corData.officialLetterId;
        data.categoryPId = corData.categoryPId;
        data.categoryCId = corData.categoryCId;
        data.platformName = corData.platformName;
        data.platformUrl = corData.platformUrl;
        if(corData.businessType){
          data.businessType = Number(corData.businessType)
        }else{
         // data.businessType = corData.businessType
        }
        
      } else {
        data.epName = userDetail.epName;
        data.creditCode = userDetail.creditCode;
        data.representative = userDetail.representative;
        data.busiLicenseId = userDetail.busilicenseid;
        data.officialLetterId = userDetail.officialLetterId;
        data.categoryPId = userDetail.categoryPid;
        data.categoryCId = userDetail.categoryCid;
        data.platformName = userDetail.platformName;
        data.platformUrl = userDetail.platformUrl;
        if(userDetail.businessType){
            data.businessType = Number(userDetail.businessType)
        }else{
           // data.businessType = userDetail.businessType
        }
      
      }
      //表单验证通过调用接口
      this.$refs[formName].validate((valid) => {
          if (valid) {
          this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        if (this.state === "") {
          sendApply(data).then(res => {
            this.$message.closeAll();
            this.loading.close();
            if (res.retCode === 0) {
              this.$message({
                message: "提交成功",
                type: "success"
              });
              localStorage.removeItem(`applyDataSave${userData.uid}`);
              this.getUserInfo();
            } else {
              if (res.retCode === 3505) {
                //目前后端没细分错误码后期完善
                this.$message.error("短信验证码填写有误,请重试");
              } else {
                this.$message.error(res.retMsg);
              }
            }
          });
        } else {
          updateApply(data).then(res => {
            this.$message.closeAll();
            this.loading.close();
            if (res.retCode == 0) {
              this.$message({
                message: "修改成功",
                type: "success"
              });
              localStorage.removeItem(`applyDataSave${userData.uid}`);
              this.getUserInfo();
            } else {
              this.$message.error(res.retMsg);
            }
          });
        }
          } else {
            return false;
          }
      });
      // if (data.contact.trim() === "") {
      //   this.$message.error("申请人姓名不能为空");
      // } else if (!this.validateChinglish(data.contact)) {
      //   this.$message.error("申请人姓名格式不正确");
      // } else if (data.title.trim() === "") {
      //   this.$message.error("申请人职务不能为空");
      // } else if (!this.validateChinglish(data.title)) {
      //   this.$message.error("申请人职务格式不正确");
      // } else if (data.idcard.trim() === "") {
      //   this.$message.error("身份证号不能为空");
      // } else if (cardIdReg.test(data.idcard) === false) {
      //   this.$message.error("身份证号格式不正确");
      // } else if (data.mobile.trim() === "") {
      //   this.$message.error("申请人手机号不能为空");
      // } else if (!this.validatePhone(data.mobile)) {
      //   this.$message.error("手机号码格式不正确");
      // } else if (data.verifyCode.trim() === "") {
      //   this.$message.error("短信验证码不能为空");
      // } else if (!data.idcardFrontId) {
      //   this.$message.error("请上传身份证正面");
      // } else if (!data.idcardBackId) {
      //   this.$message.error("请上传身份证反面");
      // } else {
      //   this.loading = this.$loading({
      //     lock: true,
      //     text: "Loading",
      //     spinner: "el-icon-loading",
      //     background: "rgba(0, 0, 0, 0.7)"
      //   });
      //   if (this.state === "") {
      //     sendApply(data).then(res => {
      //       this.$message.closeAll();
      //       this.loading.close();
      //       if (res.retCode === 0) {
      //         this.$message({
      //           message: "提交成功",
      //           type: "success"
      //         });
      //         localStorage.removeItem(`applyDataSave${userData.uid}`);
      //         this.getUserInfo();
      //       } else {
      //         debugger
      //         if (res.retCode === 3505) {
      //           //目前后端没细分错误码后期完善
      //           this.$message.error("短信验证码填写有误,请重试");
      //         } else {
      //           this.$message.error(res.retMsg);
      //         }
      //       }
      //     });
      //   } else {
      //     updateApply(data).then(res => {
      //       this.$message.closeAll();
      //       this.loading.close();
      //       if (res.retCode == 0) {
      //         this.$message({
      //           message: "修改成功",
      //           type: "success"
      //         });
      //         localStorage.removeItem(`applyDataSave${userData.uid}`);
      //         this.getUserInfo();
      //       } else {
      //         this.$message.error(res.retMsg);
      //       }
      //     });
      //   }
      // }
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.$message.closeAll();
        if (res.retCode === 0) {
          localStorage.setItem("userData", JSON.stringify(res.detail));
          this.$router.push({ path: "/authSuccess" });
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    saveFormData() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const data = {
        contact: this.form.applyName,
        title: this.form.applyPost,
        mobile: this.form.applyPhone,
        verifyCode: this.form.vertify,
        cardId: this.form.cardId,
        cardFrontId: this.cardFrontId,
        cardBackId: this.cardBackId
      };
      localStorage.setItem(
        `applyDataSave${userData.uid}`,
        JSON.stringify(data)
      );
    },
    onSave() {
      this.saveFormData();
      this.isvisible = true;
      this.dailogData = enterRelInfo.saveDraftS;
    },
    getVertify() {
      this.$message.closeAll();
      if (this.form.applyPhone.trim() === "") {
        this.$message.error("联系人手机号不能为空");
      } else if (!this.validatePhone(this.form.applyPhone)) {
        this.$message.error("手机号码格式不正确");
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        this.sendPhoneCode();
      }
    },
    sendPhoneCode() {
      const data = {
        mobile: this.form.applyPhone,
        type: this.state === "" ? 1 : 2
      };
      sendPhoneCode(data).then(res => {
        this.$message.closeAll();
        this.loading.close();
        if (res.retCode == 0) {
          this.isInter = true;
          const that = this;
          const inters = setInterval(() => {
            that.inter--;
            if (that.inter <= 0) {
              clearInterval(inters);
              that.isInter = false;
              that.inter = 60;
            }
          }, 1000);
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.$refs.form.clearValidate('imageUrl')
      this.cardFrontId = res.id;
      if (res.retCode === 0) {
        this.downloadCardFront(res.id);
      } else {
        for (const key in statusCode) {
          if (key == res.retCode) {
            this.$message.error(statusCode[key]);
            break;
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      this.$message.closeAll();
      const isJPG = file.type;
      if (
        isJPG != "image/png" &&
        isJPG != "image/jpeg" &&
        isJPG.indexOf("pdf") < 0
      ) {
        this.$message.error("上传仅支持PNG、JPG、JPGE格式!");
        return false;
      } else if (file.size >= 5242880) {
        this.$message.error("该信息上传大小不得超过5M,请重试");
        return false;
      }
      this.frontLoading = true;
    },
    handleAvatarSuccess1(res, file) {
      this.$refs.form.clearValidate('imageUrl1')
      this.cardBackId = res.id;
      if (res.id) {
        this.downloadCardBack(res.id);
      } else {
        for (const key in statusCode) {
          if (key == res.retCode) {
            this.$message.error(statusCode[key]);
            break;
          }
        }
      }
    },
    beforeAvatarUpload1(file) {
      this.$message.closeAll();
      const isJPG = file.type;
      if (
        isJPG != "image/png" &&
        isJPG != "image/jpeg" &&
        isJPG.indexOf("pdf") < 0
      ) {
        this.$message.error("上传仅支持PNG、JPG、JPGE格式!");
        return false;
      } else if (file.size >= 5242880) {
        this.$message.error("该信息上传大小不得超过5M");
        return false;
      }
      this.backLoading = true;
    },
    colseDialog(val) {
      this.isvisible = val;
    },
    operateButton(val) {
      if (val == "search-confirm") {
        //直接关闭
        this.isvisible = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.auth {
  min-height: 100%;
  ::v-deep .el-dialog--center {
    width: 50%;
    max-height: 90%;
    top: 5%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0 !important;
    ::v-deep .el-dialog__footer {
      position: fixed;
      bottom: 5%;
      text-align: center;
      width: 48%;
      background: #fff;
      .submit {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(
          -90deg,
          rgba(49, 97, 187, 1) 0%,
          rgba(42, 72, 156, 1) 100%
        );
        border-radius: 4px;
        border: none;
      }
    }
  }
  .content {
    width: 1000px;
    height: auto;
    box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
    margin: 0 auto;
    padding-top: 85px;
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #3160ba;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #3160ba;
      border-color: #3160ba;
    }
    ::v-deep .el-cascader {
      width: 100%;
    }
    ::v-deep .avatar-uploader .el-upload {
      border: none;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 100;
      .example {
        margin-left: 20px;
        height: 80px;
      }
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .uploadadd {
      width: 76px;
      height: 76px;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 76px;
      height: 76px;
      line-height: 76px;
      text-align: center;
    }
    .avatar {
      width: 120px;
      // height: 76px;
      display: block;
    }
    .suf {
      position: absolute;
      right: 0;
    }
    .suffix {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #244bb0;
      cursor: pointer;
    }
    .suffix2 {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #b4b4b4;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
    ::v-deep .el-form-item__label {
      width: 175px !important;
      color: #333333 !important;
    }
    ::v-deep .el-form-item__content {
      margin-left: 175px !important;
      p.upload-tip {
        color: #999999;
        font-size: 14px;
        line-height: inherit;
        margin-top: 0;
      }
      span.uploaded {
        color: #999999;
        font-size: 14px;
        position: absolute;
        bottom: 60px;
        left: 130px;
      }
    }
    .title {
      color: #333333;
      font-size: 16px;
      margin-top: 33px;
      text-align: center;
    }
    .topImg {
      width: 100%;
      height: 157px;
    }
    .form {
      width: 600px;
      // text-align: center;
      margin: 50px auto;
    }
    .form2 {
      width: 70%;
      margin: 50px auto 0;
      display: flex;
      ::v-deep .el-form-item {
        width: 50%;
        margin-bottom: 0;
      }
      ::v-deep .el-form-item__label {
        width: 200px !important;
        color: #333333 !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 200px !important;
      }
    }
    .uploadText {
      width: 100%;
      color: #999999;
      font-size: 14px;
      p {
        text-align: center;
        a {
          color: #2b4fa5;
          text-decoration: none;
        }
      }
    }
    .agree {
      margin: 0px 0 34px;
      text-align: center;
      ::v-deep .el-checkbox {
        font-weight: bold;
        margin-right: 0;
      }
    }
    .footBtn2 {
      width: 100%;
      text-align: center;
      padding-bottom: 46px;
      .submit {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(
          -90deg,
          rgba(49, 97, 187, 1) 0%,
          rgba(42, 72, 156, 1) 100%
        );
        border-radius: 4px;
        border: none;
      }
      .save {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #3160ba;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #3160ba;
      }
    }
  }
}
</style>
